@font-face {
  font-family: 'PublicFont';
  src: url('./../fonts/Vazir.ttf');

}

.barCodeBox{
  position: fixed;
  top: 0;
  right: 0;
  background-color: #fff;
  width: 100%;
  height: 100%;
}

.fixedHeader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background-color: #203674;
  padding-top: 20px;
}

.fisDetail{
  margin-bottom: 10px;
  color: #fff;
  background-color: #14b177;
}


.before {
  max-height: 0;
  transition: all 0.5s ease-in-out;
  overflow: hidden;
  box-sizing: border-box;
  display: block;
}

.after {
  max-height: 300px;
}

.showNotification {
  position: absolute;
  inset: 0px auto auto 0px;
  margin: 0px;
  transform: translate(0px, 46px);
  width: 350px;
}

.profileShow {
  width: 224px;
  position: absolute;
  inset: 0px auto auto 0px;
  margin: 0px;
  transform: translate(0px, 52px);
}

.cursor-pointer {
  cursor: pointer;
}

.dropDown-user {
  opacity: 1 !important;
  visibility: visible !important;
  transform: translate3d(0, 0, 0) !important;
  margin-top: 25px;
}

.marginTopReset {
  margin-top: 0;
}

.Loading-dumbbell {
  height: 50px;
  padding: 3px;
  display: inline-flex;
}

.Loading-dumbbell img {
  height: 90%;
  margin-top: 5%;
  animation-name: Loadnig;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  transform: rotate(45deg);
}


@keyframes Loadnig {
  0% {
    transform: rotate(90deg);
  }

  100% {
    transform: rotate(450deg);
  }

}

.forgetNotif {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  background-color: #167878;
  z-index: 100;
  border-radius: 0 0 5px;
  transition: 0.5s;
}

.notifOff {
  top: -300px;
  right: 0;
}

.closeBtn {
  position: absolute;
  top: 10px;
  right: 15px;
  background: white;
  border-radius: 3px;
  cursor: pointer;
}

.forgetNotif p {
  width: 96%;
  margin: 30px 2% 0px 2%;
  background-color: transparent;
  border-radius: 10px;
  padding: 10px;
  font-size: 17px;
  line-height: 28px;
  color: #fff;
  text-align: justify;
}

.forgetNotif .forgetButton {
  width: 100%;
  padding: 5px;
}

.forgetButton button {
  border: none;
  outline: none;
  float: left;
  padding: 10px 15px;
  background-color: #152f72;
  border-radius: 5px;
  font-size: 17px;
  color: #fff;
  margin: 10px 0 10px 15px;
  transition: 0.3s;
}

.forgetButton button:hover {
  transform: scale(1.02);
}

.displayNon {
  display: none;
}

.roboteLink a {
  width: 94%;
  display: block;
  margin: 0px 3%;
  text-align: center;
  background-color: #152f72;
  border-radius: 5px;
  padding: 5px;
  color: #fff;
}

.registerAlert {
  width: 100%;
  background-color: #167878;
  border-radius: 5px;
}

.registerAlert p {
  width: 96%;
  margin: 30px 2% 0px 2%;
  background-color: transparent;
  border-radius: 10px;
  padding: 10px;
  font-size: 17px;
  line-height: 28px;
  color: #fff;
  text-align: justify;
}

.deleteImg {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

.startMessage {
  width: 100%;
}

.startMessage p {
  width: 90%;
  color: #fff;
  background: #00b371;
  font-size: 17px;
  text-align: center;
  padding: 10px;
  margin: 20px 5%;
  border-radius: 30px;
}


.closeDropDown {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 50;
}

.dropDownBox {
  z-index: 60 !important;
}

.pageLodingBox {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 70;
}


.pageLoadingContent {
  width: 100%;
  height: 100%;
}

.pageLoadingWaiting {
  margin: 15% auto;
}

.pageLoadingWaiting img {
  width: 55px;
  margin: 0 auto;
  animation-name: Loadnig;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  transform: rotate(45deg);
}

.pageLoadingWaiting p {
  width: 100%;
  text-align: center;
  margin: 10px 0;
  color: #fff;
  font-size: 17px;
}

.hiddenElement {
  visibility: hidden;
  height: 10px;
}

.closeSideBarMobile {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 55;
}

.closeSideBarMobileClose {
  display: none;
}

.sideMenoMobile {
  position: fixed;
  top: 0;
  left: 0;
  width: 300px;
  padding-left: 10px;
  height: 100%;
  overflow-y: scroll;
  background-color: #00012d;
  border-radius: 0 0 5px 0;
  z-index: 60;
  transition: 0.3s;
}

.sideMenoMobileClose {
  left: -400px;
}

.closeBtnSideBar {
  position: fixed;
  top: 10px;
  left: 10px;
  width: 25px;
  transition: 0.3s;
}

.closeBtnSideBarClose {
  left: -300px;
}

@media only screen and (min-width : 768px) {
  .pcDisplayNone {
    display: none !important;
  }
}

@media only screen and (max-width : 640px) {
  .marginTopSm {
    margin-top: 10px;
    margin-right: 0;
  }

  .fixedMargin {
    margin-left: -70px !important;
  }

}

@media only screen and (max-width : 370px) {
  .fixedMargin {
    margin-left: -80px !important;
  }

}

.ant-modal {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  pointer-events: none;
  position: relative;
  top: 100px;
  width: auto;
  max-width: calc(100vw - 32px);
  margin: 0 auto;
  padding-bottom: 24px;
}

.ant-modal.ant-zoom-enter,
.ant-modal.ant-zoom-appear {
  transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ant-modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}

.ant-modal-mask-hidden {
  display: none;
}

.ant-modal-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
}

.ant-modal-wrap {
  z-index: 1000;
}

.ant-modal-title {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
}

.ant-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
}

.ant-modal-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
}

.ant-modal-close-x {
  display: block;
  width: 56px;
  height: 56px;
  font-size: 16px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
  display: none;
}

.ant-modal-close:focus,
.ant-modal-close:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}

.ant-modal-header {
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
  display: none;
}

.ant-modal-body {
  padding: 0;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}

.ant-modal-footer {
  padding: 10px 16px;
  text-align: right;
  background: transparent;
  border-top: 1px solid #f0f0f0;
  border-radius: 0 0 2px 2px;
  display: none;

}

.ant-modal-footer .ant-btn+.ant-btn:not(.ant-dropdown-trigger) {
  margin-bottom: 0;
  margin-left: 8px;
}

.ant-modal-open {
  overflow: hidden;
}

.ant-modal-centered {
  text-align: center;
}

.ant-modal-centered::before {
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  content: '';
}

.ant-modal-centered .ant-modal {
  top: 0;
  display: inline-block;
  padding-bottom: 0;
  text-align: left;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .ant-modal {
    max-width: calc(100vw - 16px);
    margin: 8px auto;
  }

  .ant-modal-centered .ant-modal {
    flex: 1;
  }
}

.ant-modal-confirm .ant-modal-header {
  display: none;
}

.ant-modal-confirm .ant-modal-body {
  padding: 32px 32px 24px;
}

.ant-modal-confirm-body-wrapper::before {
  display: table;
  content: '';
}

.ant-modal-confirm-body-wrapper::after {
  display: table;
  clear: both;
  content: '';
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  display: block;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
}

.ant-modal-confirm-body .ant-modal-confirm-content {
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}

.ant-modal-confirm-body>.anticon {
  float: left;
  margin-right: 16px;
  font-size: 22px;
}

.ant-modal-confirm-body>.anticon+.ant-modal-confirm-title+.ant-modal-confirm-content {
  margin-left: 38px;
}

.ant-modal-confirm .ant-modal-confirm-btns {
  margin-top: 24px;
  text-align: right;
}

.ant-modal-confirm .ant-modal-confirm-btns .ant-btn+.ant-btn {
  margin-bottom: 0;
  margin-left: 8px;
}

.ant-modal-confirm-error .ant-modal-confirm-body>.anticon {
  color: #ff4d4f;
}

.ant-modal-confirm-warning .ant-modal-confirm-body>.anticon,
.ant-modal-confirm-confirm .ant-modal-confirm-body>.anticon {
  color: #faad14;
}

.ant-modal-confirm-info .ant-modal-confirm-body>.anticon {
  color: #1890ff;
}

.ant-modal-confirm-success .ant-modal-confirm-body>.anticon {
  color: #52c41a;
}

.ant-modal-wrap-rtl {
  direction: rtl;
}

.ant-modal-wrap-rtl .ant-modal-close {
  right: initial;
  left: 0;
}

.ant-modal-wrap-rtl .ant-modal-footer {
  text-align: left;
}

.ant-modal-wrap-rtl .ant-modal-footer .ant-btn+.ant-btn {
  margin-right: 8px;
  margin-left: 0;
}

.ant-modal-wrap-rtl .ant-modal-confirm-body {
  direction: rtl;
}

.ant-modal-wrap-rtl .ant-modal-confirm-body>.anticon {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}

.ant-modal-wrap-rtl .ant-modal-confirm-body>.anticon+.ant-modal-confirm-title+.ant-modal-confirm-content {
  margin-right: 38px;
  margin-left: 0;
}

.ant-modal-wrap-rtl .ant-modal-confirm-btns {
  text-align: left;
}

.ant-modal-wrap-rtl .ant-modal-confirm-btns .ant-btn+.ant-btn {
  margin-right: 8px;
  margin-left: 0;
}

.ant-modal-wrap-rtl.ant-modal-centered .ant-modal {
  text-align: right;
}

/* ///zoo, */



.ant-zoom-enter,
.ant-zoom-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-zoom-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-zoom-enter.ant-zoom-enter-active,
.ant-zoom-appear.ant-zoom-appear-active {
  -webkit-animation-name: antZoomIn;
  animation-name: antZoomIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.ant-zoom-leave.ant-zoom-leave-active {
  -webkit-animation-name: antZoomOut;
  animation-name: antZoomOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.ant-zoom-enter,
.ant-zoom-appear {
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.ant-zoom-enter-prepare,
.ant-zoom-appear-prepare {
  transform: none;
}

.ant-zoom-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.ant-zoom-big-enter,
.ant-zoom-big-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-zoom-big-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-zoom-big-enter.ant-zoom-big-enter-active,
.ant-zoom-big-appear.ant-zoom-big-appear-active {
  -webkit-animation-name: antZoomBigIn;
  animation-name: antZoomBigIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.ant-zoom-big-leave.ant-zoom-big-leave-active {
  -webkit-animation-name: antZoomBigOut;
  animation-name: antZoomBigOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.ant-zoom-big-enter,
.ant-zoom-big-appear {
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.ant-zoom-big-enter-prepare,
.ant-zoom-big-appear-prepare {
  transform: none;
}

.ant-zoom-big-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.ant-zoom-big-fast-enter,
.ant-zoom-big-fast-appear {
  -webkit-animation-duration: 0.1s;
  animation-duration: 0.1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-zoom-big-fast-leave {
  -webkit-animation-duration: 0.1s;
  animation-duration: 0.1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-zoom-big-fast-enter.ant-zoom-big-fast-enter-active,
.ant-zoom-big-fast-appear.ant-zoom-big-fast-appear-active {
  -webkit-animation-name: antZoomBigIn;
  animation-name: antZoomBigIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.ant-zoom-big-fast-leave.ant-zoom-big-fast-leave-active {
  -webkit-animation-name: antZoomBigOut;
  animation-name: antZoomBigOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.ant-zoom-big-fast-enter,
.ant-zoom-big-fast-appear {
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.ant-zoom-big-fast-enter-prepare,
.ant-zoom-big-fast-appear-prepare {
  transform: none;
}

.ant-zoom-big-fast-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.ant-zoom-up-enter,
.ant-zoom-up-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-zoom-up-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-zoom-up-enter.ant-zoom-up-enter-active,
.ant-zoom-up-appear.ant-zoom-up-appear-active {
  -webkit-animation-name: antZoomUpIn;
  animation-name: antZoomUpIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.ant-zoom-up-leave.ant-zoom-up-leave-active {
  -webkit-animation-name: antZoomUpOut;
  animation-name: antZoomUpOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.ant-zoom-up-enter,
.ant-zoom-up-appear {
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.ant-zoom-up-enter-prepare,
.ant-zoom-up-appear-prepare {
  transform: none;
}

.ant-zoom-up-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.ant-zoom-down-enter,
.ant-zoom-down-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-zoom-down-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-zoom-down-enter.ant-zoom-down-enter-active,
.ant-zoom-down-appear.ant-zoom-down-appear-active {
  -webkit-animation-name: antZoomDownIn;
  animation-name: antZoomDownIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.ant-zoom-down-leave.ant-zoom-down-leave-active {
  -webkit-animation-name: antZoomDownOut;
  animation-name: antZoomDownOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.ant-zoom-down-enter,
.ant-zoom-down-appear {
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.ant-zoom-down-enter-prepare,
.ant-zoom-down-appear-prepare {
  transform: none;
}

.ant-zoom-down-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.ant-zoom-left-enter,
.ant-zoom-left-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-zoom-left-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-zoom-left-enter.ant-zoom-left-enter-active,
.ant-zoom-left-appear.ant-zoom-left-appear-active {
  -webkit-animation-name: antZoomLeftIn;
  animation-name: antZoomLeftIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.ant-zoom-left-leave.ant-zoom-left-leave-active {
  -webkit-animation-name: antZoomLeftOut;
  animation-name: antZoomLeftOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.ant-zoom-left-enter,
.ant-zoom-left-appear {
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.ant-zoom-left-enter-prepare,
.ant-zoom-left-appear-prepare {
  transform: none;
}

.ant-zoom-left-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.ant-zoom-right-enter,
.ant-zoom-right-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-zoom-right-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-zoom-right-enter.ant-zoom-right-enter-active,
.ant-zoom-right-appear.ant-zoom-right-appear-active {
  -webkit-animation-name: antZoomRightIn;
  animation-name: antZoomRightIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.ant-zoom-right-leave.ant-zoom-right-leave-active {
  -webkit-animation-name: antZoomRightOut;
  animation-name: antZoomRightOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.ant-zoom-right-enter,
.ant-zoom-right-appear {
  transform: scale(0);
  opacity: 0;
  -webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.ant-zoom-right-enter-prepare,
.ant-zoom-right-appear-prepare {
  transform: none;
}

.ant-zoom-right-leave {
  -webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

@-webkit-keyframes antZoomIn {
  0% {
    transform: scale(0.2);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes antZoomIn {
  0% {
    transform: scale(0.2);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes antZoomOut {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.2);
    opacity: 0;
  }
}

@keyframes antZoomOut {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.2);
    opacity: 0;
  }
}

@-webkit-keyframes antZoomBigIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes antZoomBigIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@-webkit-keyframes antZoomBigOut {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.8);
    opacity: 0;
  }
}

@keyframes antZoomBigOut {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.8);
    opacity: 0;
  }
}

@-webkit-keyframes antZoomUpIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 0%;
    opacity: 0;
  }

  100% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }
}

@keyframes antZoomUpIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 0%;
    opacity: 0;
  }

  100% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }
}

@-webkit-keyframes antZoomUpOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }

  100% {
    transform: scale(0.8);
    transform-origin: 50% 0%;
    opacity: 0;
  }
}

@keyframes antZoomUpOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }

  100% {
    transform: scale(0.8);
    transform-origin: 50% 0%;
    opacity: 0;
  }
}

@-webkit-keyframes antZoomLeftIn {
  0% {
    transform: scale(0.8);
    transform-origin: 0% 50%;
    opacity: 0;
  }

  100% {
    transform: scale(1);
    transform-origin: 0% 50%;
  }
}

@keyframes antZoomLeftIn {
  0% {
    transform: scale(0.8);
    transform-origin: 0% 50%;
    opacity: 0;
  }

  100% {
    transform: scale(1);
    transform-origin: 0% 50%;
  }
}

@-webkit-keyframes antZoomLeftOut {
  0% {
    transform: scale(1);
    transform-origin: 0% 50%;
  }

  100% {
    transform: scale(0.8);
    transform-origin: 0% 50%;
    opacity: 0;
  }
}

@keyframes antZoomLeftOut {
  0% {
    transform: scale(1);
    transform-origin: 0% 50%;
  }

  100% {
    transform: scale(0.8);
    transform-origin: 0% 50%;
    opacity: 0;
  }
}

@-webkit-keyframes antZoomRightIn {
  0% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }

  100% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
}

@keyframes antZoomRightIn {
  0% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }

  100% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
}

@-webkit-keyframes antZoomRightOut {
  0% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }

  100% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
}

@keyframes antZoomRightOut {
  0% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }

  100% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
}

@-webkit-keyframes antZoomDownIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }

  100% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
}

@keyframes antZoomDownIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }

  100% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
}

@-webkit-keyframes antZoomDownOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }

  100% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
}

@keyframes antZoomDownOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }

  100% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
}

.ant-motion-collapse-legacy {
  overflow: hidden;
}

.ant-motion-collapse-legacy-active {
  transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}

.ant-motion-collapse {
  overflow: hidden;
  transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}

/* ////fade */


.ant-fade-enter,
.ant-fade-appear {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-fade-leave {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}

.ant-fade-enter.ant-fade-enter-active,
.ant-fade-appear.ant-fade-appear-active {
  -webkit-animation-name: antFadeIn;
  animation-name: antFadeIn;
  -webkit-animation-play-state: running;
  animation-play-state: running;
}

.ant-fade-leave.ant-fade-leave-active {
  -webkit-animation-name: antFadeOut;
  animation-name: antFadeOut;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  pointer-events: none;
}

.ant-fade-enter,
.ant-fade-appear {
  opacity: 0;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.ant-fade-leave {
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

@-webkit-keyframes antFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes antFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes antFadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes antFadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}