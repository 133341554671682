:root {
  --datepicker-color: #30368b;
  --datepicker-button-hover-color: #43428bee;
  --datepicker-background: #fff;
  --datepicker-days-background: #f7f7f7;
  --datepicker-today-background: #a7a1ff47;
  --datepicker-border-color: grey;
  --datepicker-shadow-color: #5959597a;
}

.OutSideClick{
  content: '&quot;&quot';
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 1000;
  left: 0;
}

.JDatePicker {
  display: flex;
  /*margin-top: 2em;*/
  flex-flow: column;
  flex-wrap: nowrap;
  direction: rtl;
  width: 300px;
  min-height: 210px;
  padding: 5px;
  position: fixed;
  top: calc(50% - 150px);
  left: calc(50% - 150px);
  margin-left: auto;
  margin-right: auto;
  background: var(--datepicker-background);
  border-radius: 5px;
  box-shadow: 2px 5px 7px 4px var(--datepicker-shadow-color);
  z-index: 1;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 1001;
}

.JDatePicker.ltr {
  right: unset;
  left: 0;
}

.JDatePicker .JC-HourMinute {
  display: flex;
  flex-flow: column;
  flex-wrap: nowrap;
  justify-content: center;
  width: 80px;
}
.JDatePicker .JC-Section {
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  padding: 10px 5px;
}
.JDatePicker .JC-Section-Year {
  background-color: var(--datepicker-color);
  color: var(--datepicker-background);
  border-radius: 4px;
}
.JDatePicker .JC-Buttons {
  text-align: center;
  padding-top: 5px;
  display: none;
}
.JDatePicker .JC-Clock {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  direction: ltr;
  padding: 8px;
  border-bottom: 1px solid var(--datepicker-border-color);
}
.JDatePicker .JC-Nav {
  display: inline-block;
  flex-grow: 1;
  text-align: center;
  cursor: pointer;
  /* width: 24px; */
  height: 24px;
  color: var(--datepicker-border-color);
}
.JDatePicker .JC-Nav-Year {
  color: var(--datepicker-background);
}
.JDatePicker .JC-Nav-Hour {
  border: 1px solid var(--datepicker-border-color);
  border-radius: 50%;
  height: 30px;
  width: 30px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.JDatePicker .JC-Title {
  display: inline-block;
  flex-grow: 3;
  text-align: center;
  font-weight: 700;
}
.JDatePicker button {
  font-size: 14px;
  margin: 5px;
  padding: 2px 10px;
  border-radius: 5px;
  cursor: pointer;
  color: black;
  background-color: var(--datepicker-background);
  border: 1px solid var(--datepicker-color);
  width: 40%;
}
.JDatePicker button:hover {
  color: var(--datepicker-background);
  background-color: var(--datepicker-button-hover-color);
}
.JDatePicker .MonthPicker {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  background: var(--datepicker-background);
  text-align: center;
  padding: 5px;
  top: 26px;
  border-radius: 5px;
  box-shadow: 2px 5px 7px 4px var(--datepicker-shadow-color);
  z-index: 1;
}
.JDatePicker .MonthItems:hover{
  background: var(--datepicker-today-background);
  color: black;
}
.JDatePicker .MonthItems.selected {
  background: var(--datepicker-color);
  color: var(--datepicker-background);
}
.JDatePicker .MonthItems {
  display: inline-block;
  width: 32.5%;
  text-align: center;
  cursor: pointer;
  padding: 4px 0;
  border: 0.3px solid var(--datepicker-border-color);
  font-size: 14px;
}


.days-titles div {
  border-top: 1px solid var(--datepicker-border-color);
  border-bottom: 1px solid var(--datepicker-border-color);
  width: 14.28%;
  display: inline-block;
  font-size: 14px;
  font-weight: 300;
  text-align: center;
}

.JDatePicker span:first-child{
  float: right;
  width: 15%;
}
.JDatePicker .JC-days {
  position: relative;
  display: inline-block;
  background: var(--datepicker-days-background);
  /* border-bottom: 1px solid var(--datepicker-border-color); */
  border: none;
}
.JDatePicker .JC-days .holder {
  padding: 10px 0px;
  line-height: 24px;
}
.JDatePicker .day-items{
  width: 14.28%;
  float: right;
  text-align: center;
  font-weight: 400;
  cursor: pointer;
  padding-top: 4px;
  border-radius: 4px;
}
.JDatePicker .day-items:hover {
  background-color: var(--datepicker-color);
  color: var(--datepicker-background);
}
.JDatePicker .day-items.selected {
  font-weight: 900;
  background-color: var(--datepicker-color);
  color: var(--datepicker-background);
}
.JDatePicker .day-items.today {
  background-color: var(--datepicker-today-background);
}
.JDatePicker .day-items.today:hover {
  background-color: var(--datepicker-color);
  color: var(--datepicker-background);
}
