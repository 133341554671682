.navigat {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 300;
    width: 100%;
    height: 62px;
    background: #7366FF;
    display: flex;
    justify-content: center;
    align-items: initial;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin: none !important;
}

.navigat ul {
    display: flex;
    width: 350px;
    padding: inherit;
}

.navigat ul li {
    position: relative;
    list-style: none;
    width: 70px;
    height: 54px;
    z-index: 1;
}

.navigat ul li a {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    text-align: center;
    font-weight: 500;
}

.navigat ul li a .icon {
    position: relative;
    bottom: -20px;
    display: block;
    line-height: 75px;
    font-size: 1.5em;
    text-align: center;
    transition: 0.5s;
    color: #fff;
    font-size: 26px;
    border: 0px solid rgb(0, 0, 0);
    color: black;
}

.navigat ul li.active a .icon {
    transform: translateY(-32px);
    padding: 5px;
    border: 2px solid rgb(0, 0, 0);
    color: #fff;
    background-color: rgb(0, 0, 82);
    border-radius: 50%;
}

.navigat ul li a .text {
    position: absolute;
    color: #fff;
    font-weight: bold;
    font-size: 0.75em;
    letter-spacing: 0.05em;
    white-space: nowrap;
    transition: 0.5s;
    opacity: 0;
    transform: translateY(70px);
}

.navigat ul li.active a .text {
    opacity: 1;
    transform: translateY(20px);
}

.hover {
    position: absolute;
    top: -60%;
    width: 70px;
    height: 70px;
    background: #7366FF;
    border-radius: 50%;
    border: 6px solid #222327;
    transition: 0.5s;
}

.hover.dark-mode {
    position: absolute;
    top: -60%;
    width: 70px;
    height: 70px;
    background: #7366FF;
    border-radius: 50%;
    border: 6px solid #222327;
    transition: 0.5s;
}

.navigat ul li:nth-child(1).active~.hover {
    transform: translatex(calc(70px * 0));
}

.navigat ul li:nth-child(2).active~.hover {
    transform: translatex(calc(-70px * 1));
}

.navigat ul li:nth-child(3).active~.hover {
    transform: translatex(calc(-70px * 2));
}

.navigat ul li:nth-child(4).active~.hover {
    transform: translatex(calc(-70px * 3));
}

.navigat ul li:nth-child(5).active~.hover {
    transform: translatex(calc(-70px * 4));
}

@media screen and (min-width:575px) {
    .navigat {
        display: none;
    }
}